import React from "react";
import { Link, graphql } from "gatsby";
import { Layout, Pager, Seo } from "../components";

interface PageContextData {
  tag: string;
}

interface PageInfo {
  currentPage: number;
  hasNextPage: boolean;
  itemCount: number;
  hasPreviousPage: boolean;
  pageCount: number;
  perPage: number;
  totalCount: number;
}

interface MarkdownData {
  frontmatter: {
    slug: string;
    title: string;
    tags: string[];
    date: Date;
  };
  fields: {
    slug: string;
  };
}

interface Data {
  allMarkdownRemark: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: [
      {
        node: MarkdownData;
      }
    ];
  };
}

const Tags = ({
  pageContext,
  data,
}: {
  pageContext: PageContextData;
  data: Data;
}) => {
  const { tag } = pageContext;
  const { edges, totalCount, pageInfo } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`;

  return (
    <Layout>
      <>
        <Seo title={`タグ:${tagHeader}についての一覧`} />
        <h1>{tagHeader}</h1>
        <ul>
          {edges.map(({ node }: { node: MarkdownData }) => {
            const { slug, title, tags, date } = node.frontmatter;
            return (
              <li key={slug}>
                <Link to={`/${slug}`}>
                  {date} {title} #{tags}
                </Link>
              </li>
            );
          })}
        </ul>
        <Pager pageInfo={pageInfo} />
        <Link to="/tags">All tags</Link>
      </>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 15
      skip: 0
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      pageInfo {
        currentPage
        hasNextPage
        itemCount
        hasPreviousPage
        pageCount
        perPage
        totalCount
      }
      edges {
        node {
          frontmatter {
            slug
            title
            tags
            date(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  }
`;
